import React from "react"

import Layout from "../components/layout"
import { Container, Row, Col, ProgressBar, Button, Table } from 'react-bootstrap';
import SEO from "../components/seo"
import 'bootstrap/dist/css/bootstrap.min.css';

import nikolay from "../images/nikolay.png";
import jens from "../images/jens.png";
import threehundredsharks from "../images/300sharks_cut.png";
import decentraland from "../images/decentraland_sharks.png";
import opensea from "../images/opensea.svg";
import sharkspreview from "../images/300sharks_preview.gif";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{paddingTop: 150}}>
      <Container>
        <Row>
          <Col md={6}>
            <img style={{marginTop: 20}} src={threehundredsharks} width={"100%"} alt="300 Sharks" />
            <h1>The 300 NFT sharks to clean our oceans</h1>
            <p className="cs-p">No Plan B for our oceans. 300 unique sharks (NFTs) inspired by the CryptoArt movement to save it from plastic waste. The profits will be donated to <a target="_blank" rel="noopener noreferrer" href="https://theoceancleanup.com/">The Ocean Cleanup</a> project.</p>
          </Col>
          <Col md={6} style={{textAlign: "center", paddingTop: 30}}>
            <img src={sharkspreview} alt="300 Sharks" width={"80%"} />
          </Col>
        </Row>
      </Container>
    </div>
    <div className="cs-space">
      <Container style={{textAlign: "center"}}>
        <Button size="lg" variant="secondary" id="cs-button" href="https://opensea.io/collection/300sharks"><span>Buy on </span><img width={120} src={opensea} /></Button>
        <ProgressBar animated now={2.67} />
        <p className="cs-p" style={{marginTop: 20}}>PRE-SALE HAPPENING RIGHT NOW! So far 2.67% of the 300 Sharks have been sold and they are out there collecting plastic waste from our oceans. Join the fight!</p>
        <p className="cs-p" style={{marginTop: 40}}>Stay tuned for more info on our upcoming shark batches as they warm up before the battle:</p>
        <Table striped hover>
          <thead>
            <tr>
              <th>Batch</th>
              <th>Availability</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>PRE-SALE</td>
              <td>7/15</td>
              <td>0.1ETH</td>
            </tr>
            <tr>
              <td>THE JOURNEY</td>
              <td>15/15</td>
              <td>0.2ETH</td>
            </tr>
            <tr>
              <td>THE PREPARATION</td>
              <td>20/20</td>
              <td>0.5ETH</td>
            </tr>
            <tr>
              <td>THE BATTLE</td>
              <td>249/249</td>
              <td>1ETH</td>
            </tr>
            <tr>
              <td>ENDGAME</td>
              <td>1/1</td>
              <td>10ETH</td>
            </tr>
          </tbody>
        </Table>
      </Container>
      <Container style={{marginTop: 50}}>
        <h1 style={{textAlign: "center"}}>3,200 USD</h1>
        <p style={{fontSize: 25, textAlign: "center"}}>Donated so far and counting! Big hug to all contributors that care for our oceans!
            <br></br>Find more information on our Donation Pledge below.
        </p>
      </Container>
    </div>
    <div className="cs-space">
      <Container>
        <h2>Project Description</h2>
        <p className="cs-p">Oceans cover more than 70% of the world’s surface, yet most people treat them like trash. Every year, millions of tons of plastic enter our oceans impacting more than 600 marine species and causing millions of them to die. Plastic pollution in our oceans also impacts our own health since toxic pollutants are carried into our supply chain.
        <br></br><br></br>Like the Spartans fighting their epic battle, 300 brave Sharks have now set out to fight plastic pollution and restore our oceans – and you can be a part of them! 
        <br></br><br></br>300 Sharks is a charitable project with the mission to clean our oceans. 90% of the profits will be donated to The Ocean Cleanup, by and large the most ambitious non-profit foundation we could find in the space. You can find more details in our Donation Pledge below.</p>
      </Container>
    </div>
    <div className="cs-space">
      <Container>
        <h2>The Sharks</h2>
        <p className="cs-p">
        Cleaning our oceans is no easy feat! This is why every 300 Shark is equipped with unique tools to combat plastic pollution in our oceans.
        <br></br><br></br>There will be only 300 sharks registered as unique pieces of art (NFTs) on the Ethereum blockchain making it one of the rarest NFT projects to date. Each shark has a unique set of properties with a variable rarity (background/cape/body/L hand/R hand/head) and is randomly generated out of 33,264 total possible combinations.
        </p>
      </Container>
    </div>
    <div className="cs-space">
      <Container>
        <h2>Find us in the Metaverse</h2>
        <p className="cs-p">
        Sometimes we hang out in Decentraland.
        <br></br>And sometimes you can see our sharks! Shout out to <a target="_blank" rel="noopener noreferrer" href="https://innkeeper.link/">innkeeper.link</a>:
        <br></br><br></br>
        <img src={decentraland} width={"100%"} alt="Decentraland" />
        </p>
      </Container>
    </div>
    <div className="cs-space">
      <Container>
        <h2>Not sure how to proceed with the fight?</h2>
        <p className="cs-p">You are hooked (pun intended) but you don't know how to buy a shark?
        <br></br><br></br>Here is a <a target="_blank" rel="noopener noreferrer" href="https://buycryptonft.com/a-step-by-step-guide-on-how-to-buy-nfts-on-opensea-b4bdad8fa5c">good guide</a> on how to buy an NFT (=shark) on OpenSea.
        <br></br><br></br>And here is a quick summary of the steps:
        <ol>
          <li>Download a crypto wallet like <a target="_blank" rel="noopener noreferrer" href="hhttps://metamask.io/">MetaMask</a> (super easy setup as browser plugin)</li>
          <li>Use MetaMask to convert some money to ETH (you can do it directly through the wallet!)</li>
          <li>Go to <a target="_blank" rel="noopener noreferrer" href="https://opensea.io/collection/300sharks">our page on OpenSea</a>  and sign in with MetaMask</li>
          <li>Buy a shark and help clean the oceans!</li>
        </ol>
        Fully lost? Fear not! Contact us at info@300sharks.com
        </p>
      </Container>
    </div>
    <div className="cs-space">
      <Container>
        <h2>The Donation Pledge</h2>
        <p className="cs-p">
        We pledge to donate 90% of the profits* from all 300 Sharks sold to “The Ocean Cleanup”**. Having looked at many different ocean cleanup projects, we have come to the view that The Ocean Cleanup is the best non-profit foundation with the mission to clean up 90% of the floating ocean plastic pollution. In particular, we like their ambition to go for real moonshot projects building large ocean systems and interceptors. You can read more about their milestones <a target="_blank" rel="noopener noreferrer" href="https://theoceancleanup.com/milestones/">here</a>.
        <br></br><br></br>Saving our oceans is not a one time effort! To make the sharks everlasting warriors against plastic waste, 300 Sharks takes a 5% royalty fee on each trade beyond the initial acquisition through an OpenSea smart contract. 90% of these royalty fees are also directly donated to the The Ocean Cleanup.
        <br></br><br></br>*The 300 Sharks project is subject to taxation in the Federal Republic of Germany. Proceeds from all sharks will immediately be converted to FIAT currency (EUR) and a 19% tax rate applies. 90% of the remaining proceeds will then immediately be donated to the The Ocean Cleanup.
        <br></br><br></br>**The Ocean Cleanup is a fully registered non-profit organization in The Netherlands and the US 501(c)(3). We will publish a confirmation and proof of all donations directly here on this website.
        </p>
      </Container>
    </div>
    <div className="cs-space">
      <Container>
        <div style={{textAlign: "center", width: "100%", marginBottom: 50}}>
          <h2>Core Contributors</h2>
        </div>
        <Row>
          <Col md={6}>
            <div style={{textAlign: "center", width: "100%"}}>
              <img src={jens} width={"40%"} className="rounded" alt="Jens" />
              <h3 style={{marginTop: 20}}>Jens</h3>
            </div>
            <p className="cs-p">
              Kite surfer by heart, Jens loves spending time at the beach and on the water. He has travelled many parts of world and seen the impact of our modern lifestyles on the environment, which was his motivation for this project.
              <br></br><br></br>As long as Jens can think back he has a deep passion for new technologies and the impact they have on our life. Jens got involved in the Blockchain world several years ago and since then spend a significant amount of time in understanding this exciting new technology. He runs a Blockchain course with >10k students on Udemy.
              <br></br><br></br>Jens gathered deep tech experience in Executive positions at both Palantir Technologies as well as Amazon.
            </p>
          </Col>
          <Col md={6}>
            <div style={{textAlign: "center", width: "100%"}}>
              <img src={nikolay} width={"40%"} className="rounded" alt="Nikolay" />
              <h3 style={{marginTop: 20}}>Nikolay</h3>
            </div>
            <p className="cs-p">
            Sad times for Nikolay are when he is not at the water (any water body bigger than a pond). A major life goal is to move back to the water (preferably the ocean) but environmental disasters seem to loom around every corner, so it was time to take action.
            <br></br><br></br>Nikolay has been tinkering with tech or reading about it since the dawn of the dinosaurs 🦖  /or at least from Windows 98 onwards. He has lost as much money or more money than you not hodling ETH properly over the years. And he was trying to pitch NFTs at hackathons before it was cool and naturally nobody cared.
            <br></br><br></br>Somewhere between fullstack engineer, PM, k8s adventurer and CTO during the day, crypto fan at night. He has worked in various tech companies big and small most recently at Celonis trying to make German tech great again with powerful buzzwords such as big data.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
);

export default IndexPage;
